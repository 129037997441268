import { Container, Grid, Segment } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import bmwkSVG from 'volto-dlr/static/bmwi_logo_de.svg';
import bmwkENSVG from 'volto-dlr/static/bmwi_logo_en.svg';
import twitterSVG from 'volto-dlr/icons/twitter.svg';
import youtubeSVG from 'volto-dlr/icons/yt.svg';
import facebookSVG from 'volto-dlr/icons/fb.svg';
import mastodonSVG from 'volto-dlr/icons/mastodon.svg';
import vimeoSVG from 'volto-dlr/icons/vimeo.svg';
import instagramSVG from 'volto-dlr/icons/instagram.svg';
import ThreadsSVG from 'volto-dlr/icons/threads-logo-white.svg';
import linkedInSVG from 'volto-dlr/icons/linkedIn.svg';
import flickerSVG from 'volto-dlr/icons/flicker.svg';
import xingSVG from 'volto-dlr/icons/xing.svg';
import soundcloudSVG from 'volto-dlr/icons/soundcloud.svg';
import rssSVG from 'volto-dlr/icons/rss.svg';
import { isEmpty } from 'lodash';

const messages = defineMessages({
  title: {
    id: 'Visit us on',
    defaultMessage: 'Visit us on',
  },
});

const FooterBottom = () => {
  const intl = useIntl();
  const currentLang = useSelector((state) => state.intl.locale);
  const iNavRootInfo = useSelector((state) => state.nearestInavRoot);
  const subsite = iNavRootInfo.subsite;
  const isSubsite = !isEmpty(subsite);
  const hasSocialLinks =
    subsite.facebook_url ||
    subsite.X_url ||
    subsite.vimeo_url ||
    subsite.threads_url ||
    subsite.youtube_url ||
    subsite.instagram_url ||
    subsite.linkedIn_url ||
    subsite.flickr_url ||
    subsite.soundcloud_url ||
    subsite.mastodon_url ||
    subsite.xing_url ||
    subsite.rss_url;
  return isSubsite ? (
    <>
      <div className="footer-bottom">
        <Segment vertical>
          <Container>
            <Grid stackable columns={subsite.show_bmwk_logo_in_footer ? 3 : 1}>
              <Grid.Column className="left">
                {hasSocialLinks && (
                  <FormattedMessage
                    id="Visit us here:"
                    defaultMessage="Visit us here:"
                  />
                )}
                <div className="rrss-links">
                  {subsite.facebook_url && (
                    <a aria-label="DLR Facebook" href={subsite.facebook_url}>
                      <Icon
                        title={`${intl.formatMessage(messages.title)} Facebook`}
                        name={facebookSVG}
                        size="30px"
                      />
                    </a>
                  )}
                  {subsite.linkedIn_url && (
                    <a aria-label="DLR linkedin" href={subsite.linkedIn_url}>
                      <Icon
                        title={`${intl.formatMessage(messages.title)} Linkedin`}
                        name={linkedInSVG}
                        size="30px"
                        style={{ background: '#0288D1' }}
                      />
                    </a>
                  )}
                  {subsite.xing_url && (
                    <a aria-label="Dlr Xing" href={subsite.xing_url}>
                      <Icon
                        title={`${intl.formatMessage(messages.title)} Xing`}
                        name={xingSVG}
                        size="30px"
                      />
                    </a>
                  )}
                  {subsite.X_url && (
                    <a aria-label="DLR X" href={subsite.X_url}>
                      <Icon
                        title={`${intl.formatMessage(messages.title)} X`}
                        name={twitterSVG}
                        size="20px"
                        style={{ background: 'black', padding: '5px' }}
                      />
                    </a>
                  )}
                  {subsite.mastodon_url && (
                    <a
                      aria-label="Mastodon"
                      rel="me"
                      href={subsite.mastodon_url}
                    >
                      <Icon
                        title={`${intl.formatMessage(messages.title)} Mastodon`}
                        name={mastodonSVG}
                        size="30px"
                      />
                    </a>
                  )}
                  {subsite.threads_url && (
                    <a aria-label="DLR Threads" href={subsite.threads_url}>
                      <Icon
                        title={`${intl.formatMessage(messages.title)} Threads`}
                        name={ThreadsSVG}
                        size="20px"
                        style={{ background: 'black', padding: '5px' }}
                      />
                    </a>
                  )}
                  {subsite.youtube_url && (
                    <a aria-label="DLR YouTube" href={subsite.youtube_url}>
                      <Icon
                        title={`${intl.formatMessage(messages.title)} YouTube`}
                        name={youtubeSVG}
                        size="30px"
                      />
                    </a>
                  )}

                  {subsite.vimeo_url && (
                    <a aria-label="DLR Vimeo" href={subsite.vimeo_url}>
                      <Icon
                        title={`${intl.formatMessage(messages.title)} Vimeo`}
                        name={vimeoSVG}
                        size="30px"
                      />
                    </a>
                  )}

                  {subsite.instagram_url && (
                    <a aria-label="DLR Instagram" href={subsite.instagram_url}>
                      <Icon
                        title={`${intl.formatMessage(
                          messages.title,
                        )} Instagram`}
                        name={instagramSVG}
                        size="30px"
                      />
                    </a>
                  )}

                  {subsite.flickr_url && (
                    <a aria-label="DLR Flicker" href={subsite.flickr_url}>
                      <Icon
                        title={`${intl.formatMessage(messages.title)} Flicker`}
                        name={flickerSVG}
                        size="30px"
                      />
                    </a>
                  )}
                  {subsite.soundcloud_url && (
                    <a
                      aria-label="DLR SoundCloud"
                      href={subsite.soundcloud_url}
                    >
                      <Icon
                        title={`${intl.formatMessage(
                          messages.title,
                        )} SoundCloud`}
                        name={soundcloudSVG}
                        size="30px"
                      />
                    </a>
                  )}
                  {subsite.rss_url && (
                    <Link aria-label="DLR RSS" to={subsite.rss_url}>
                      <Icon
                        title={`${intl.formatMessage(messages.title)} RSS`}
                        name={rssSVG}
                        size="30px"
                      />
                    </Link>
                  )}
                </div>
              </Grid.Column>
              {subsite.show_bmwk_logo_in_footer && (
                <>
                  <Grid.Column
                    className="spacer"
                    tablet={3}
                    computer={4}
                  ></Grid.Column>
                  <Grid.Column>
                    {currentLang === 'de' ? (
                      <a
                        href="https://www.bmwk.de/Navigation/DE/Home/home.html"
                        aria-label="BMWK Logo"
                      >
                        <img
                          src={bmwkSVG}
                          className="bmwklogo"
                          loading="lazy"
                          decoding="async"
                          alt="Bundesministerium für Wirtschaft und Klimaschutz"
                          width="240"
                          height="247"
                        />
                      </a>
                    ) : (
                      <a
                        href="https://www.bmwk.de/Navigation/EN/Home/home.html"
                        aria-label="BMWK Logo"
                      >
                        <img
                          src={bmwkENSVG}
                          className="bmwklogo"
                          loading="lazy"
                          decoding="async"
                          alt="Federal Ministry for Economic Affairs and Climate Action"
                          width="240"
                          height="247"
                        />
                      </a>
                    )}
                  </Grid.Column>
                </>
              )}
            </Grid>
          </Container>
        </Segment>
      </div>
    </>
  ) : (
    <div className="footer-bottom">
      <Segment vertical>
        <Container>
          <Grid stackable columns={3}>
            <Grid.Column className="left">
              <FormattedMessage
                id="Visit us here:"
                defaultMessage="Visit us here:"
              />
              <div className="rrss-links">
                <a
                  aria-label="DLR Facebook"
                  href={
                    currentLang === 'de'
                      ? 'https://www.facebook.com/DLRde/'
                      : 'https://www.facebook.com/DLRen/'
                  }
                >
                  <Icon
                    title={`${intl.formatMessage(messages.title)} Facebook`}
                    name={facebookSVG}
                    size="30px"
                  />
                </a>
                <a
                  aria-label="DLR X"
                  href={
                    currentLang === 'de'
                      ? 'https://twitter.com/DLR_de'
                      : 'https://twitter.com/DLR_en'
                  }
                >
                  <Icon
                    name={twitterSVG}
                    title={`${intl.formatMessage(messages.title)} X`}
                    size="20px"
                    style={{ background: 'black', padding: '5px' }}
                  />
                </a>
                <a
                  aria-label="Mastodon"
                  rel="me"
                  href="https://social.bund.de/@dlr"
                >
                  <Icon
                    title={`${intl.formatMessage(messages.title)} Mastodon`}
                    name={mastodonSVG}
                    size="30px"
                  />
                </a>
                <a
                  aria-label="DLR Threads"
                  href="https://www.threads.net/@germanaerospacecenter"
                >
                  <Icon
                    title={`${intl.formatMessage(messages.title)} Threads`}
                    name={ThreadsSVG}
                    size="20px"
                    style={{ background: 'black', padding: '5px' }}
                  />
                </a>
                <a
                  aria-label="DLR YouTube"
                  href="https://www.youtube.com/dlrde"
                >
                  <Icon
                    title={`${intl.formatMessage(messages.title)} YouTube`}
                    name={youtubeSVG}
                    size="30px"
                  />
                </a>
                <a aria-label="DLR Vimeo" href="https://vimeo.com/dlr">
                  <Icon
                    title={`${intl.formatMessage(messages.title)} Vimeo`}
                    name={vimeoSVG}
                    size="30px"
                  />
                </a>
                <a
                  aria-label="DLR Instagram"
                  href={
                    currentLang === 'de'
                      ? 'https://www.instagram.com/germanaerospacecenter'
                      : 'https://www.instagram.com/dlr.en/'
                  }
                >
                  <Icon
                    title={`${intl.formatMessage(messages.title)} Instagram`}
                    name={instagramSVG}
                    size="30px"
                  />
                </a>
                <a
                  aria-label="DLR Flicker"
                  href="https://www.flickr.com/photos/dlr_de/"
                >
                  <Icon
                    title={`${intl.formatMessage(messages.title)} Flicker`}
                    name={flickerSVG}
                    size="30px"
                  />
                </a>
                <a
                  aria-label="DLR SoundCloud"
                  href="https://soundcloud.com/dlrde"
                >
                  <Icon
                    title={`${intl.formatMessage(messages.title)} SoundCloud`}
                    name={soundcloudSVG}
                    size="30px"
                  />
                </a>
                <Link
                  aria-label="DLR RSS"
                  to={currentLang === 'de' ? '/de/rss' : '/en/rss'}
                >
                  <Icon
                    title={`${intl.formatMessage(messages.title)} RSS`}
                    name={rssSVG}
                    size="30px"
                  />
                </Link>
              </div>
            </Grid.Column>
            <Grid.Column
              className="spacer"
              tablet={3}
              computer={4}
            ></Grid.Column>
            <Grid.Column>
              {currentLang === 'de' ? (
                <a
                  href="https://www.bmwk.de/Navigation/DE/Home/home.html"
                  aria-label="BMWK Logo"
                >
                  <img
                    src={bmwkSVG}
                    className="bmwklogo"
                    loading="lazy"
                    decoding="async"
                    alt="Bundesministerium für Wirtschaft und Klimaschutz"
                    width="240"
                    height="247"
                  />
                </a>
              ) : (
                <a
                  href="https://www.bmwk.de/Navigation/EN/Home/home.html"
                  aria-label="BMWK Logo"
                >
                  <img
                    src={bmwkENSVG}
                    className="bmwklogo"
                    loading="lazy"
                    decoding="async"
                    alt="Federal Ministry for Economic Affairs and Climate Action"
                    width="240"
                    height="247"
                  />
                </a>
              )}
            </Grid.Column>
          </Grid>
        </Container>
      </Segment>
    </div>
  );
};

export default FooterBottom;
