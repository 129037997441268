import { defineMessages } from 'react-intl';
import { defaultStylingSchema } from '../schema';
import { v4 as uuid } from 'uuid';
import { removeFromArray } from '@plone/volto/helpers/Utils/Utils';

const messages = defineMessages({
  color: {
    id: 'Color',
    defaultMessage: 'Color',
  },
  buttonText: {
    id: 'Button text',
    defaultMessage: 'Button text',
  },
  slideBackgroundColor: {
    id: 'Slide Background Color',
    defaultMessage: 'Slide Background Color',
  },
  flagColor: {
    id: 'Flag color',
    defaultMessage: 'Flag color',
  },
  flagAlign: {
    id: 'Flag align',
    defaultMessage: 'Flag align',
  },
  aspectRatio: {
    id: 'Aspect ratio',
    defaultMessage: 'Aspect ratio',
  },
});

const SLIDER_COLORS = [
  { name: 'green', label: 'Green' },
  { name: 'green2', label: 'Light Green' },
  { name: 'blue', label: 'Blue' },
  { name: 'blue2', label: 'Light blue' },
  { name: 'orange', label: 'Orange' },
  { name: 'orange2', label: 'Light orange' },
  { name: 'white', label: 'White' },
  { name: 'black', label: 'Black' },
];

export const sliderBlockSchemaEnhancer = ({
  formData,
  schema,
  intl,
  navRoot,
}) => {
  schema.properties.slides.default = [
    { '@id': uuid(), flagAlign: 'left', flagColor: 'green' },
  ];
  schema.properties.slides.schema.properties.href.selectedItemAttrs.push(
    'image_field',
  );
  schema.properties.slides.schema.properties.href.selectedItemAttrs.push(
    'image_scales',
  );
  schema.properties.slides.schema.fieldsets[0].fields.push('buttonText');
  schema.properties.slides.schema.properties.buttonText = {
    title: intl.formatMessage(messages.buttonText),
  };
  schema.properties.slides.schema.fieldsets[0].fields.push('flagAlign');
  schema.properties.slides.schema.properties.flagAlign = {
    widget: 'inner_align',
    title: intl.formatMessage(messages.flagAlign),
    actions: ['left', 'right'],
    default: 'left',
  };
  schema.properties.slides.schema.fieldsets[0].fields.push('flagColor');
  schema.properties.slides.schema.properties.flagColor = {
    widget: 'color_picker',
    title: intl.formatMessage(messages.flagColor),
    colors: SLIDER_COLORS,
    default: 'green',
  };

  // Remove the description from the slider but only if we are not in the karriere, schoollab or next portals
  if (
    !navRoot?.['@id']?.startsWith('/de/karriere') &&
    !navRoot?.['@id']?.startsWith('/en/careers') &&
    !navRoot?.['@id']?.startsWith('/de/schoollab') &&
    !navRoot?.['@id']?.startsWith('/en/schoollab') &&
    !navRoot?.['@id']?.startsWith('/de/next') &&
    !navRoot?.['@id']?.startsWith('/en/next')
  ) {
    schema.properties.slides.schema.fieldsets[0].fields = removeFromArray(
      schema.properties.slides.schema.fieldsets[0].fields,
      schema.properties.slides.schema.fieldsets[0].fields.indexOf(
        'description',
      ),
    );
  }

  schema.properties = {
    ...schema.properties,
    aspectRatio: {
      fieldsets: [{ fields: ['aspectRatio'] }],
    },
  };
  schema.fieldsets[0].fields.unshift('aspectRatio');
  schema.properties.aspectRatio = {
    widget: 'select',
    title: intl.formatMessage(messages.aspectRatio),
    choices: [
      ['aspectRatio16_9', '16:9'],
      ['aspectRatio24_11', '24:11'],
    ],
    default: 'aspectRatio16_9',
  };
  return defaultStylingSchema({ schema, formData, intl });
};
